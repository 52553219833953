export default class {

    constructor(key) {
        key = key.replace('m', '');
        this.key = key;
        this.notes = ['C', 'C&#9839;', 'D', 'D&#9839;', 'E', 'F', 'F&#9839;', 'G', 'G&#9839;', 'A', 'B&#9837;', 'B&#9838;'];
        this.notesB = ['C', 'D&#9837;', 'D', 'E&#9837;', 'E', 'F', 'G&#9837;', 'G', 'A&#9837;', 'A', 'B&#9837;', 'B&#9838;'];
        this.majorScaleNumbers = [0, 2, 4, 5, 7, 9, 11, 0, 2, 4, 5, 7, 9, 11];
        this.chords = {
            '': [1, 3, 5],
            maj7: [1, 3, 5, 7],
            maj9: [1, 3, 5, 7, 9],
            maj13: [1, 3, 5, 7, 9, 13],
            m: [1, '3b', 5],
            m7: [1, '3b', 5, 7],
            m7b5: [1, '3b', '5b', 7],
            m9: [1, '3b', 5, 7, 9],
            m13: [1, '3b', 5, 7, 9, 13],
            dim: [1, '3b', '5b'],
        };
        this.diatonic = [
            'I', 'ii', 'iii', 'IV', 'V', 'vi', 'VII:dim'
        ];
        this.progressions = [
            ['ii', 'V', 'I'],
            [1, 6, 4, 5],
            [7, 3, 6, 'v', 1, 4],
            ['Vb:dim', 'VIb:dim', 'VII:dim', 'vi'],
        ];
        this._circleOfFives = this.circleOfFives();
        this._allKeys = this.allKeys();
        this._keyNotes = this.keyNotes();
        this._scale = this.scale();
        this._majorScale = this.majorScale();
        this._fullScale = this.fullScale();
    }

    circleOfFives() {
        var c = {};
        var index = 0;
        var index2 = 0;
        c[0] = this.notes[index];
        for (var i = 0; i < 11; i++) {
            index += 7;
            if (index > 11) index -= 12;
            c[i + 1] = this.notes[index];
            index2 -= 7;
            if (index2 < 0) index2 += 12;
            c[-(i + 1)] = this.notesB[index2];
        }
        return c;
    }

    allKeys() {
        var a = [];
        this.notes.forEach(function (item, index) {
            a.push(item);
            if (this.notes[index] != this.notesB[index]) a.push(this.notesB[index]);
        }, this);
        return a;
    }


    keyNotes() {
        const circle = this.circleOfFives;
        const searchTerm = this.key;
        var index = parseInt(Object.keys(circle).find(key => circle[key] === searchTerm));
        if (index < 0) return this.notesB;
        return this.notes;
    }


    scale() {
        var myScale = [];
        const start = this.notes.indexOf(this.key);
        const keyNotes = this.keyNotes();
        var j;
        for (var i = start; i <= start + 11; i++) {
            j = i < 12 ? i : i - 12;
            myScale.push(keyNotes[j]);
        }
        return myScale;
    }


    majorScale() {
        var s = [];
        const scale = this.scale();
        this.majorScaleNumbers.forEach(function (number) {
            s.push(scale[number]);
        });
        return s;
    }


    fullScale() {
        var s = {};
        const scale = this.scale();
        var ctr = 1;
        this.majorScaleNumbers.forEach(function (number) {
            if (number > 11) number -= 12;
            s[ctr] = scale[number];
            s[ctr + 'b'] = (number > 0) ? scale[number - 1] : scale[number + 11];
            s[ctr + '#'] = (number < 11) ? scale[number + 1] : scale[number - 11];
            ctr++;
        });
        return s;
    }


    getChord(intervals) {
        var chord = [];
        const fullScale = this.fullScale();
        intervals.forEach(function (interval) {
            chord.push(fullScale[interval]);
        });
        return chord;
    }

    getChordByDesignator(item) {
        var suffix = '';
        if (!isNaN(item)) {
            return this.getChordByDesignator(this.diatonic[item - 1]);
        }
        if (item.indexOf(':') != -1) {
            var tmp = item.split(':');
            item = tmp[0];
            suffix = tmp[1];
        }
        item = this.replaceToNormal(item);
        var index = this.romanToInt(item.toUpperCase());
        if (item.substr(-1) == 'b') index+='b';
        if (item.substr(-1) == '#') index+='#';
        if ((suffix == '') && (item == item.toLowerCase())) suffix = 'm';
        var chord = this._fullScale[index] + suffix;
        return chord;
    }

    getProgression(progression) {
        var chords = [];
        progression.forEach(function (item) {
            chords.push(this.getChordByDesignator(item));
        }, this);
        return chords;
    }

    romanToInt(str1) {
        if (str1 == null) return -1;
        var num = this.charToInt(str1.charAt(0));
        var pre, curr;

        for (var i = 1; i < str1.length; i++) {
            curr = this.charToInt(str1.charAt(i));
            pre = this.charToInt(str1.charAt(i - 1));
            if (curr <= pre) {
                num += curr;
            } else {
                num = num - pre * 2 + curr;
            }
        }

        return num;
    }

    charToInt(c) {
        switch (c) {
            case 'I':
                return 1;
            case 'V':
                return 5;
            case 'X':
                return 10;
            case 'L':
                return 50;
            case 'C':
                return 100;
            case 'D':
                return 500;
            case 'M':
                return 1000;
            default:
                return 0;
        }
    }

    preferredName(note) {
        const circle = this.circleOfFives;
        const index = this.notes.indexOf(note);
        var circleIndex = parseInt(Object.keys(circle).find(key => circle[key] === note));
        if (circleIndex < 0) return this.notesB[index];
        return note;
    }

    replaceToNormal(s) {
        return s.replaceAll('&#9837;', 'b').replaceAll('&#9838;', '').replaceAll('&#9839;', '#');
    }

    replaceToMusic(s) {
        return s.replaceAll('b', '&#9837;').replaceAll('Bb', '@@@').replaceAll('B', 'B&#9838;').replaceAll('@@@', 'Bb').replaceAll('&#9839;', '#');
    }
}