<template>
  <div id="app" class="container">
    <h1>Piano Akkorde und Progressionen</h1>
    <div class="form-group">
      <select class="form-control" v-model="keyName" @input="switchKey($event)">
        <option v-for="(note,index) in key.allKeys()" :key="index" :value="note" v-html="note" />
      </select>
    </div>
    <hr/>
    <h2>{{ keyName }}</h2>
    <table class="table">
      <thead>
      <tr>
        <th v-for="i in 7" :key="i">{{ i }}</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td v-for="i in 7" :key="i" v-html="key.majorScale()[i - 1]" />
      </tr>
      <tr>
        <td v-for="i in 7" :key="i" v-html="key.getChordByDesignator(key.diatonic[i - 1])" />
      </tr>
      </tbody>
    </table>
    <tr/>
    <hr />
    <h3>Akkorde</h3>
    <table class="table table-striped">
      <tbody>
      <tr v-for="(chord,label,index) in key.chords" :key="index">
        <td v-html="keyName+label" />
        <td v-html="key.getChord(chord).join(' - ')" />
      </tr>
      </tbody>
    </table>
    <hr />
    <h3>Progressionen</h3>
    <table class="table table-striped">
      <tbody>
      <tr v-for="(progression,index) in key.progressions" :key="index">
        <td>{{ progression.join(' - ') }}</td>
        <td v-html="key.getProgression(progression).join(' - ')" />
      </tr>
      </tbody>
    </table>
    <hr />
    <h3>Diatonisch ii - V - I</h3>
    <table class="table table-striped">
      <tbody>
      <tr v-for="(tone,index) in key.diatonic" :key="index">
        <td v-html="tone" />
        <td v-html="key.getChordByDesignator(tone)" />
        <td v-html="getKey(key.getChordByDesignator(tone)).getProgression(['ii', 'V']).join(' - ')+' - '+key.getChordByDesignator(tone)" />
      </tr>
      </tbody>
    </table>
    <hr />
    <h3>Auffüllen (ii - V - I)</h3>
    <div class="form-group">
      <input type="text" class="form-control" :value="key.replaceToNormal(sequence)" />
    </div>
    <div v-if="sequence">
      <span v-for="(item,index) in sequence.split(' ')" :key="index">
        <span v-if="(item != '') && (index > 0)" v-html="getKey(item).getProgression(['ii', 'V']).join(' - ')" style="margin: 0 15px;"/> <span style="font-weight: bold;" v-html="item" />
      </span>
    </div>
  </div>
</template>

<script>

import Key from "./classes/Key.js";

export default {
  name: 'App',
  computed: {
    key() {
      return new Key(this.keyName);
    },
  },
  data() {
    return {
      keyName: 'C',
      sequence: 'C Am F G',
    }
  },
  methods: {
    getKey(name) {
      var myKey = new Key(name);
      console.log(name, myKey)
      return myKey;
    },
    switchKey(event) {
      var tmpKey = new Key(event.target.value);
      this.sequence = tmpKey.getProgression([1,6,4,5]).join(' ');
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
